<template>
  <div class="buy-ticket">
    <h2>我的门票</h2>
    <div class="tabs">
      <div class="bar-list" :class="'selected' + selectedTab">
        <div :class="`tab-bar ${selectedTab === item.id ? 'selected' : ''}`" v-for="(item, index) in barList" @click="toggleTab(item)" :key="'center_ticket_tab_'+index">
          {{ item.text }}
        </div>
      </div>
      <div class="content">
        <div v-show="selectedTab === 0">
          <div class="tab-pane" v-if="ticketList.length > 0" v-show="selectedTab === 0">
            <div class="ticket-container">
              <div class="ticket" v-for="(item, index) in ticketList" :key="'center_ticket_list_'+index">
                <div :class="`status ${item.isActivation === '1' ? 'actived' : ''}`">
                  {{ item.isActivation === "1" ? "已使用" : "未使用" }}
                </div>
                <div class="ticket-number">
                  订单号：<span>{{ item.number }}</span>
                </div>
                <div class="ticket-number">
                  票&nbsp;&nbsp;&nbsp;号：<span>{{ item.bank }}</span>
                </div>
                <h3 class="ticket-name">{{ item.title }}</h3>
                <div class="active-url" v-if="item.isActivation === '0'">
                  激活链接：<a :href="item.linkUrl" :id="item.number" target="_blank">{{ item.linkUrl }}</a><br />
                  <span @click="copyUrl(item)">点击复制</span>
                </div>
                <div class="active-name" v-else>
                  激活人姓名：<span>{{ item.userName }}</span>
                </div>
              </div>
            </div>
            <div class="page-wrapper">
              <div class="order-page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :page-size="pageLimit"
                  :page-count="pageCount"
                  :total="allTicketList.length"
                  @current-change="togglePage"
                  @prev-click="togglePage"
                  @next-click="togglePage"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <h3 class="no-ticket" v-else>暂无门票</h3>
        </div>
        <div  v-show="selectedTab === 1">
          <div class="tab-pane" v-if="activeTicket.length > 0" v-show="selectedTab === 1">
            <div class="ticket-container">
              <div class="my-ticket" v-for="(item, index) in activeTicket" :key="'center_ticket_list_act_'+index">
                <div class="meeting-name">
                  {{ item.schTitle }}
                </div>
                <div class="ticket-number">
                  票&nbsp;&nbsp;&nbsp;号：<span>{{ item.bank }}</span>
                </div>
                <h3 class="ticket-name">{{ item.ticketName }}</h3>
                <a class="look-ticket" :href="item.electronicTicketUrl" target="_blank">查看我的电子门票</a>
              </div>
            </div>
            <div class="page-wrapper">
              <div class="order-page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :page-size="pageLimit"
                  :page-count="activeTicket.length"
                  :total="activeTicket.length"
                  @current-change="toggleActivePage"
                  @prev-click="toggleActivePage"
                  @next-click="toggleActivePage"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <h3 class="no-ticket" v-else>暂无门票</h3> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sid, getMyTicket, getBuyTicket, getMyTicketUniversal } from "@/utils/api.js";

export default {
  props: {
    token: String,
    type: String,
  },
  data() {
    return {
      selectedTab: 0,
      barList: [
        {
          text: "我购买的门票",
          id: 0,
        },
        {
          text: "我的门票信息",
          id: 1,
        },
      ],
      allTicketList: [],
      ticketList: [], // 我购买的门票
      currentPage: 1, // 当前为第几页
      pageCount: 0, // 一共有几页
      pageLimit: 4,
      totalAmount: 0, // 订单总金额
      allActiveTicket: [],
      activeTicket: [], // 我的门票信息
      ActiveCurrentCount: 0, // 我的门票当前为第几页
      ActivePageCount: 0, // 我的门票一共有几页
    };
  },
  mounted() {
    this.getCurrentTicket();
    this.getAllTicket();
    this.getCurrentActive();
    this.getAllActiveTicket();
    // new api - ？
    this.getMyTicketAll();
    this.getMyTicketBuy();
  },
  methods: {
    // 切换数据
    togglePage(page) {
      this.currentPage = page;
      this.getCurrentTicket();
    },
    toggleActivePage(page) {
      this.currentPage = page;
      this.getCurrentActive();
    },
    toggleTab(item) {
      this.selectedTab = item.id;
    },
    // 复制链接
    copyUrl(item) {
      window.getSelection().removeAllRanges();
      //   const number = item.number;
      let copyDOM = document.getElementById(`${item.number}`);
      // let copyDOM = this.$refs.number;
      let range = document.createRange();
      range.selectNode(copyDOM);
      window.getSelection().addRange(range);
      try {
        // let successful = document.execCommand("copy");
        window.alert("复制成功");
      } catch (e) {
        window.alert("复制失败");
      }
      window.getSelection().removeAllRanges();
    },
    // 我的门票 - ？
    getMyTicketAll() {
      this.$http
        .get(getMyTicketUniversal, {
          params: {
            flag: 2, // 全部门票
            type: 'all',
            // sid: sid(this.global.year),
            // page: this.currentPage,
            // limit: this.pageLimit,
            choose: 1,
            token: this.token,
            relation: true,
          },
        })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log('getMyTicket-a ', data);
        })
    },
    getMyTicketBuy() {
      this.$http
        .get(getMyTicketUniversal, {
          params: {
            flag: 1, // 购买的门票
            type: 'all',
            // sid: sid(this.global.year),
            // page: this.currentPage,
            // limit: this.pageLimit,
            choose: 1,
            token: this.token,
            relation: true,
          },
        })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log('getMyTicket-b ', data);
        })
    },
    // 我自己购买的门票
    getCurrentTicket() {
      this.$http
        .get(getBuyTicket, {
          params: {
            token: this.token,
            type: this.type,
            sid: sid(this.global.year),
            page: this.currentPage,
            limit: this.pageLimit,
            relation: true,
          },
        })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === "200") {
            this.ticketList = data.data;
          } else if (data.status === "1026" || data.status === "1008") {
            console.log("登录过期");
          } else {
            console.log("服务器繁忙，请重试");
          }
        });
    },
    getAllTicket() {
      this.$http
        .get(getBuyTicket, {
          params: {
            token: this.token,
            type: this.type,
            sid: sid(this.global.year),
            relation: true,
          },
        })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === "200") {
            this.allTicketList = data.data;
          } else if (data.status === "1026" || data.status === "1008") {
            console.log("登录过期");
          } else {
            console.log("服务器繁忙，请重试");
          }
        });
    },
    // 我自己拥有的门票(我激活的门票)
    getCurrentActive() {
      this.$http
        .get(getMyTicket, {
          params: {
            token: this.token,
            type: this.type,
            sid: sid(this.global.year),
            page: this.ActiveCurrentCount,
            limit: this.pageLimit,
          },
        })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === "200") {
            this.activeTicket = data.data;
          } else if (data.status === "1026" || data.status === "1008") {
            console.log("登录过期");
          } else {
            console.log("服务器繁忙，请重试");
          }
        });
    },
    getAllActiveTicket() {
      this.$http
        .get(getMyTicket, {
          params: {
            token: this.token,
            type: this.type,
            sid: sid(this.global.year),
            choose: 1,
          },
        })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === "200") {
            this.ActivePageCount = data.data;
          } else if (data.status === "1026" || data.status === "1008") {
            console.log("登录过期");
          } else {
            console.log("服务器繁忙，请重试");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-ticket {
  padding: 33px;
  h2 {
    margin-bottom: 25px;
    color: #5c5c5c;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
  }
  .tabs {
    .bar-list {
      border-bottom: 2px solid #efefef;
      display: flex;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 144px;
        height: 5px;
        bottom: 0;
        background: var(--main-color);
        transition: all linear 0.3s;
      }
      .tab-bar {
        padding: 23px 21px;
        color: #999999;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        cursor: pointer;
        &.selected {
          color: #333333;
        }
      }
      &.selected0::before {
        left: 0;
      }
      &.selected1::before {
        left: 144px;
      }
    }
    .content {
      .tab-pane {
        padding-top: 2px;
        padding-left: 9px;
        padding-right: 20px;
        .ticket-container {
          // height: 550px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .ticket {
          margin: 21px 6px 0;
          width: 404px;
          height: 254px;
          padding-top: 12px;
          padding-bottom: 19px;
          background: #f8f8f8;
          box-sizing: border-box;
          .status {
            margin-top: 9px;
            margin-right: 24px;
            margin-bottom: 12px;
            position: relative;
            font-size: 14px;
            color: var(--main-color);
            text-align: right;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              right: 46px;
              transform: translateY(-50%);
              width: 8.5px;
              height: 8.5px;
              border-radius: 50%;
              background: var(--main-color);
            }
            &.actived {
              color: var(--main-color);
            }
            &.actived::before {
              background: var(--main-color);
            }
          }
          .ticket-number {
            margin-bottom: 9px;
            padding-left: 35px;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            letter-spacing: 2px;
            text-align: left;
            span {
              color: #999;
              font-weight: 300;
              letter-spacing: 0;
            }
          }
          .ticket-name {
            margin-top: 12px;
            margin-bottom: 20px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
          }
          .active-url {
            text-align: center;
            color: #333;
            font-size: 16px;
            font-weight: 500;
            a {
              text-decoration: underline;
              color: #999;
              font-weight: 300;
            }
            span {
              text-decoration: underline;
              cursor: pointer;
              font-weight: 400;
            }
          }
          .active-name {
            text-decoration: underline;
            text-align: center;
            color: #999999;
            font-size: 14px;
            font-weight: 300;
          }
        }
        .my-ticket {
          margin: 21px 6px 0;
          width: 404px;
          height: 223px;
          padding-top: 12px;
          padding-bottom: 19px;
          background: #f8f8f8;
          text-align: left;
          .status {
            margin-right: 24px;
            position: relative;
            font-size: 14px;
            color: var(--main-color);
            text-align: right;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              right: 46px;
              transform: translateY(-50%);
              width: 8.5px;
              height: 8.5px;
              border-radius: 50%;
              background: var(--main-color);
            }
            &.actived {
              color: var(--main-color);
            }
            &.actived::before {
              background: var(--main-color);
            }
          }
          .meeting-name {
            margin-top: 20px;
            margin-bottom: 9px;
            padding-left: 35px;
            font-size: 18px;
            font-weight: 500;
            color: #333;
          }
          .ticket-number {
            margin-bottom: 9px;
            padding-left: 35px;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            letter-spacing: 2px;
            span {
              color: #999;
              font-weight: 300;
              letter-spacing: 0;
            }
          }
          .ticket-name {
            margin-top: 24px;
            margin-bottom: 30px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
          }

          .look-ticket {
            margin-left: auto;
            margin-right: auto;
            display: block;
            text-decoration: underline;
            text-align: center;
            color: #999999;
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
          }
        }
        .page-wrapper {
          padding-top: 30px;
          display: flex;
          justify-content: center;
          .total-page {
            margin-right: 10px;
            font-size: 14px;
            color: #999999;
            display: flex;
            align-items: center;
          }
        }
      }
      .no-ticket {
        padding-top: 100px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
@media (max-width: 767px) {
  .buy-ticket {
    padding: 10px 4vw;
    .tabs {
      .bar-list {
        .tab-bar {
          font-size: 16px;
        }
      }
      .content {
        .tab-pane {
          padding-left: 4px;
          padding-right: 12px;
          .ticket {
            height: auto;
            .ticket-number {
              padding-left: 12px;
              font-size: 16px;
            }
            .ticket-name {
              font-size: 20px;
            }
          }
          .my-ticket {
            height: auto;
            .meeting-name,
            .ticket-number {
              padding-left: 12px;
              font-size: 16px;
            }
            .ticket-name {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>