<template>
  <div class="settings">
    <h4>个人设置</h4>
    <div class="input-info">
      <p><span>个人信息</span>来和我们说下你的情况吧</p>
      <div class="input-info-con">
        <div class="input-wrapper">
          昵称：<input type="text" v-model="name" placeholder="请输入昵称" />
        </div>
        <div class="input-wrapper">
          公司：<input
            type="text"
            v-model="company"
            placeholder="请输入公司名称"
          />
        </div>
        <div class="input-wrapper">
          部门：<input
            type="text"
            v-model="department"
            placeholder="请输入部门名称"
          />
        </div>
        <div class="input-wrapper">
          职位：<input
            type="text"
            v-model="position"
            placeholder="请输入职位"
          />
        </div>
        <div class="input-wrapper">
          手机号：<input
            type="text"
            v-model="phone"
            disabled
            placeholder="请输入手机号"
          />
        </div>
        <div class="input-wrapper">
          邮箱：<input type="text" v-model="email" placeholder="请输入邮箱" />
        </div>
      </div>
      <button @click="confirmChange">确认修改</button>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/utils/api.js";

const infoList = {
  name: "昵称",
  company: "公司",
  department: "部门",
  position: "职位",
  email: "邮箱",
};
export default {
  props: {
    token: String,
  },
  data() {
    return {
      name: '',
      company: '',
      department: '',
      position: '',
      phone: '',
      email: '',
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$http
        .get(getUserInfo, {
          params: {
            token: this.token,
          },
        })
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.status === "200") {
            const { name, company, department, position, phone, email } = res.data;
            this.name = name;
            this.company = company;
            this.department = department;
            this.position = position;
            this.phone = phone;
            this.email = email;
          } else {
            console.log("获取用户信息失败");
          }
        });
    },
    // 个人设置 get获取 post更改
    confirmChange(e) {
      // !$(e.target).attr('disabled')
      // $(e.target).attr({'disabled':'disabled'})
      // $(e.target).removeAttr('disabled')
      if(!$(e.target).attr('disabled')) {
        const formData = new FormData();

        for (let key in infoList) {
          if (!this[key] || this[key].trim().length<=0) {
            window.alert(`${infoList[key]}不能为空`);
            return;
          } else if (
            key === "email" &&
            !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(this.email.trim())
          ) {
            window.alert("请输入正确格式的邮箱");
            return;
          }
          formData.append(key, this[key]);
        }

        $(e.target).attr({'disabled':'disabled'})
        this.$http
          .post(`${getUserInfo}?token=${this.token}`, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
          })
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            console.log(res);
            if (res.status === "200") {
              window.alert("修改成功");
              let h = localStorage.getItem('link_before_complete') || false;
              if(h) {
                localStorage.removeItem('link_before_complete');
                this.$router.push( h );
              }
            } else {
              console.log("设置失败");
            }
            $(e.target).removeAttr('disabled');
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  background: #fff;
  padding-left: 34px;
  padding-right: 34px;
  h4 {
    padding-top: 34px;
    padding-bottom: 11px;
    border-bottom: 2px solid #efefef;
    font-size: 24px;
    font-weight: 600;
    color: #5c5c5c;
    text-align: left;
  }
  .input-info {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 27px;
    display: inline-block;
    padding: 50px 35px 57px 35px;

    .input-wrapper {
      margin-bottom: 27px;
      &:first-child {
        margin-top: 45px;
      }
      &:last-child {
        margin-bottom: 50px;
      }
    }
    p {
      font-size: 14px;
      font-weight: 600;
      color: #666666;
      text-align: left;
      border-left: 5px solid var(--main-color);
      span {
        font-size: 16px;
        color: #333333;
        padding: 0 20px 0 15px;
      }
    }
    .input-info-con {
      width: fit-content;
      margin: 0 auto;
      text-align: right;
    }
    input {
      min-width: 300px;
      padding-left: 15px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(203, 203, 203, 1);
      border-radius: 5px;
      height: 42px;
      font-size: 15px;
      font-weight: 500;
      color: #999999;
      outline-width: 1px;
      &::placeholder,
      &::-webkit-input-placeholder {
        font-size: 15px;
        color: #ccc;
      }
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        font-size: 15px;
        color: #ccc;
      }
    }
    button {
      width: 130px;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      border: none;
      background: var(--main-color);
    }
  }
}
@media (max-width: 767px) {
  .settings {
    .input-info {
      padding-left: 0;
      padding-right: 0;
      input {
        min-width: auto;
      }
    }
  }
}
</style>