<template>
  <div class="invoice">
    <div class="title">
      <h2>开具发票</h2>
    </div>
    <div class="tabs" v-show="!showInvoiceInfo">
      <div class="bar-list">
        <div class="tab-bar" v-for="(item, index) in barList" @click="toggleTab(item)" :key="'center_invoice_tab_'+index">
          {{ item.text }}
        </div>
      </div>
      <div class="content">
        <ul v-if="orderList.length > 0">
          <li class="table-title">
            <label for="typeall">
              <input
                id="typeall"
                class="invoice-checkbox"
                type="checkbox"
                name="itype"
                data-type="100"
                :checked="allChecked"
                @change="toggleAllOrder"
            /></label>
            <p>订单名称</p>
            <p>实付金额</p>
          </li>
          <li class="table-row" v-for="(item, index) in orderList" :key="'center_invoice_order_'+index">
            <input
              class="invoice-checkbox"
              type="checkbox"
              :checked="selectedOrderList.indexOf(item.id || item.orderId) > -1"
              @change="toggleOrderId(item, $event)"
            />
            <p v-html="item.title || item.orderTitle || ''"></p>
            <p v-html="item.realPayAmount"></p>
          </li>
        </ul>
        <h3 class="no-ticket" v-else>暂无订单</h3>
        <div class="invoice-pages" v-if="orderList.length > 0">
          <div @click="openInvoice">开具发票</div>
          <p>
            可开具发票 <span>￥{{ totalAmount }}</span>，已选择订单总额 <span>￥{{ checkedAmount }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="info" v-show="showInvoiceInfo">
      <div class="bar-list">
        <div class="tab-bar">发票信息</div>
      </div>
      <div class="content">
        <div class="input-wrapper">
          *发票金额:
          <div class="input-content">
            <p class="amount">¥{{ invoiceAmount }}</p>
          </div>
        </div>
        <div class="input-wrapper">
          *发票类型:
          <div class="input-content invoice-type">
            <label v-for="(item,key,index) in invoiceTypeList" :for="key" :key="'type'+index">
              <input
                :id="key"
                type="radio"
                name="itype"
                :data-type="index+1"
                @click="toggleType"
              />{{ item.name }}
            </label>
          </div>
        </div>
        <div class="input-wrapper">
          *发票内容:
          <div class="input-content">
            <label for="rzero">
              <input type="radio" name="invoicecontent" checked="true" />
              电子普通发票</label
            >
          </div>
        </div>
        <div class="input-wrapper">
          *发票抬头:
          <div class="input-content">
            <input
              class="input-text"
              type="text"
              name="invoicecontent"
              v-model="invoiceTitle"
              placeholder="请填写发票抬头"
            />
          </div>
        </div>
        <div class="input-wrapper">
          *纳税人识别号:
          <div class="input-content">
            <input
              class="input-text"
              type="text"
              name="invoicecontent"
              v-model="invoiceNumber"
              placeholder="请填写纳税人识别号"
            />
          </div>
        </div>
      </div>
      <div class="bar-list">
        <div class="tab-bar">收件人邮箱</div>
      </div>
      <div class="content">
        <div class="input-wrapper">
          *电子邮箱:
          <div class="input-content">
            <input
              class="input-text"
              type="text"
              name="invoicecontent"
              id="rzero"
              v-model="email"
              placeholder="请填写电子邮箱"
            />
          </div>
        </div>
        <div class="input-wrapper">
          <div class="btn-wrapper">
            <button class="btn back" @click="goBack">返回上级</button>
            <button class="btn confirm" @click="confirmInvoice">
              确认提交
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sid, getUserOrder, getUserOrderUniversal, openInvoice } from "@/utils/api.js";

let selectedList = [];
export default {
  props: {
    token: String,
    userName: String,
    userPhone: String,
  },
  data() {
    return {
      barList: [
        {
          text: "已购订单",
          id: 0,
        },
      ],
      selectedTab: 0,
      orderIdList: "",
      orderList: [],
      selectedOrderList: [], // 选中的订单列表
      totalAmount: 0, // 订单总金额
      checkedAmount: 0, // 选中的订单总金额
      invoiceAmount: 0, // 开票金额
      showInvoiceInfo: false,
      invoiceType: "",
      invoiceTitle: "",
      invoiceNumber: "",
      email: "",
      checkInvoiceList: {
        invoiceType: "发票类型",
        invoiceTitle: "发票抬头",
        invoiceNumber: "纳税人识别号",
        email: "电子邮箱",
      },
      invoiceTypeList: {
        'typeone': { type:1, name:'会议费' },
        'typetwo': { type:2, name:'培训费' },
        'typethree': { type:3, name:'会务费' },
      },
    };
  },
  // mounted() {
  created() {
    this.getOrder();
  },
  computed: {
    allChecked: function () {
      let flag = true;
      this.orderList.map((item) => {
        if (this.selectedOrderList.indexOf(item.id || item.orderId) <= -1) {
          flag = false;
        }
      });
      return flag;
    },
  },
  methods: { 
    toggleTab(item) {
      let id = item.id || item.orderId;
      if(this.selectedTab != id){
        this.selectedTab = id;
      }
    },
    toggleType(e) {
      this.invoiceType = e.target.dataset.type;
    },
    goBack() {
      this.showInvoiceInfo = false;
    },
    //   申请开票
    confirmInvoice(e) {
      if(!$(e.target).attr('disabled')) {
        for (let key in this.checkInvoiceList) {
          if (key === "invoiceType" && this[key] === "") {
            window.alert(`请选择${this.checkInvoiceList[key]}`);
            return false;
          } else if (this[key] === "") {
            window.alert(`请填写${this.checkInvoiceList[key]}`);
            return false;
          } else if (
            key === "email" &&
            !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(this[key])
          ) {
            window.alert(`请填写正确的${this.checkInvoiceList[key]}`);
            return false;
          }
        }
        $(e.target).attr({'disabled':'disabled'})
        this.$http
          .post(
            `${openInvoice}?token=${this.token}`,
            {
              orderId: this.orderIdList,
              invoice_data: {
                title: this.invoiceTitle,
                code: this.invoiceNumber,
                phone: this.userPhone,
                name: this.userName,
                email: this.email,
                type: this.invoiceType,
                invoiceValue: this.totalAmount,
              },
            },
            { emulateJSON: true }
          )
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            console.log(data);
            if (data.status === "200") {
              this.$emit("applyInvoiceSuccess");
            } else if (data.status === "1026" || data.status === "1008") {
              console.log("登录过期");
            } else if (data.status === "1013") {
              console.log("订单不存在");
            } else {
              console.log("服务器繁忙，请重试");
            }
            setTimeout(() => {
              $(e.target).removeAttr('disabled');
            }, 200)
          });
      }
    },
    toggleAllOrder() {
      if (!this.allChecked) {
        this.selectedOrderList = this.orderList.map((item) => item.id || item.orderId);
        this.checkedAmount = this.totalAmount;
      } else {
        this.selectedOrderList = [];
        this.checkedAmount = 0;
      }
    },
    toggleOrderId(item, ev) {
      ev.target.checked ? this.checkedAmount+=parseFloat(item.realPayAmount) : this.checkedAmount-=parseFloat(item.realPayAmount);
      this.checkedAmount = parseFloat(this.checkedAmount.toFixed(2));
      // console.log(item.realPayAmount, ev.target.checked, this.checkedAmount);
      const index = this.selectedOrderList.indexOf(item.id || item.orderId);
      if (index <= -1) {
        this.selectedOrderList.push(item.id || item.orderId);
      } else {
        this.selectedOrderList.splice(index, 1);
      }
    },
    getOrder() {
      this.$http
        // .get(getUserOrder, {
        //   params: {
        //     token: this.token,
        //     sid: sid(this.global.year),
        //     choose: 1,
        //   },
        // })
        .get(getUserOrderUniversal, { params: {
          choose: 1,
          sid: sid(this.global.year),
          source: 1,
          type: 1,
          token: this.token,
        } })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === "200") {
            this.orderList = data.data.resData.filter(
              (item) => !item.is_invoice || parseInt(item.is_invoice)<=0
            );
            this.computeNumberAmount();
            // console.log(this.orderList);
          } else if (data.status === "1026" || data.status === "1008") {
            console.log("登录过期");
          } else {
            console.log("服务器繁忙，请重试");
          }
        });
    },
    openInvoice() {
      if (this.selectedOrderList.length <= 0) {
        window.alert("请选择您要开具发票的订单");
        return;
      }
      selectedList = [];
      this.showInvoiceInfo = true;
      this.orderIdList = "";
      this.selectedOrderList.map((item) => {
        if (this.orderIdList === "") {
          this.orderIdList += item;
        } else {
          this.orderIdList += "," + item;
        }
      });
      this.orderList.map((item) => {
        if (this.selectedOrderList.indexOf(item.id || item.orderId) > -1) {
          selectedList.push(item);
        }
      });
      this.computeInvoiceAmount(selectedList);
    },
    computeNumberAmount() {
      this.totalAmount = this.orderList.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          parseFloat(currentValue.realPayAmount)
          //  * parseInt(currentValue.goodsNum)
        );
      }, 0);
    },
    computeInvoiceAmount(selectedList) {
      this.invoiceAmount = selectedList.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          parseFloat(currentValue.realPayAmount)
          //  * parseInt(currentValue.goodsNum)
        );
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  padding: 33px;
  .title {
    display: flex;
    justify-content: space-between;
    h2 {
      margin-bottom: 25px;
      color: #5c5c5c;
      font-size: 24px;
    }
  }
  .tabs {
    .bar-list {
      border-bottom: 2px solid #efefef;
      display: flex;
      position: relative;
      .tab-bar {
        padding: 23px 21px;
        color: #333;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          width: 110px;
          height: 5px;
          left: 0;
          bottom: 0;
          background: var(--main-color);
        }
        &.selected {
          color: #333333;
        }
      }
    }
    .content {
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 40px;
        border: 1px solid #dddddd;
        border-bottom: none;
        .table-title {
          background: #f4f6f9;
          padding: 9px 0 9px 20px;
        }
        li {
          width: 100%;
          margin-bottom: 0;
          padding: 20px 0 20px 20px;
          border-bottom: 1px solid #dddddd;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          .invoice-checkbox {
            width: 15px;
            height: 15px;
            margin: 0;
            margin-right: 30px;
            border: 1px solid #dddddd;
            background: #ffffff;
            border-radius: 0;
          }
          label {
            margin-bottom: 0;
          }
          p {
            margin-bottom: 0;
            font-size: 16px;
            color: #999999;
            &:nth-of-type(1) {
              width: 85%;
              text-align: left;
            }
            &:nth-of-type(2) {
              width: 15%;
              text-align: center;
            }
          }
        }
      }
      .invoice-pages {
        margin-left: 8px;
        line-height: 36px;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        display: flex;
        flex-direction: row-reverse;
        div {
          margin-left: 13px;
          padding: 0 10px;
          background: var(--main-color);
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }
        p {
          margin-bottom: 0;
          font-size: 14px;
          span {
            margin-left: 4px;
            color: #ff0e0e;
            font-size: 18px;
          }
        }
      }
      .no-ticket {
        padding-top: 100px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
  .info {
    .bar-list {
      border-bottom: 2px solid #efefef;
      display: flex;
      position: relative;
      .tab-bar {
        padding: 23px 21px;
        color: #333;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          width: 110px;
          height: 5px;
          left: 0;
          bottom: 0;
          background: var(--main-color);
        }
        &.selected {
          color: #333333;
        }
      }
    }
    .content {
      max-width: 500px;
      padding-top: 21px;
      .input-wrapper {
        margin-right: auto;
        margin-bottom: 27px;
        padding-left: 2em;
        font-size: 14px;
        color: #999;
        line-height: 30px;
        display: flex;
        box-sizing: border-box;
        .input-content {
          margin-left: 16px;
          min-width: 350px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .amount {
            color: #ff0e0e;
            font-size: 21px;
          }
          &.invoice-type {
            justify-content: flex-start;
            label {
              margin-right: 60px;
            }
          }
        }
        label {
          display: flex;
          align-items: center;
          input {
            margin: 0 6px 0 0;
          }
        }
        .input-text {
          width: 100%;
          height: 28px;
          font-size: 15px;
          font-weight: 400;
          color: #999999;
          padding-left: 12px;
          outline: none;
          border: 1px solid #cccccc;
        }
        .input-text::placeholder,
        .input-text::-webkit-input-placeholder {
          font-size: 14px;
          color: #ccc;
        }
        .input-text:-moz-placeholder,
        .input-text::-moz-placeholder,
        .input-text:-ms-input-placeholder {
          font-size: 14px;
          color: #ccc;
        }
        .btn-wrapper {
          margin-left: auto;
          margin-right: auto;
          .btn {
            width: 130px;
            height: 40px;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            border: none;
            background: var(--main-color);
            cursor: pointer;
            &.back {
              margin-right: 12px;
              border: 1px solid var(--main-color);
              background: #fff;
              color: var(--main-color);
            }
          }
        }
        &:last-child {
          padding-left: 0;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .invoice {
    .tabs {
      .content {
        ul {
          li {
            p {
              &:nth-of-type(1) {
                width: 60%;
              }
              &:nth-of-type(2) {
                width: 30%;
              }
            }
          }
        }
      }
    }
    .info {
      .content {
        .input-wrapper {
          .input-content {
            min-width: auto;
            &.invoice-type {
              display: flex;
              justify-content: space-between;
              label {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .invoice {
    .tabs .content .invoice-pages {
      margin-left: 0;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-end;
      div {
        width: fit-content;
        height: fit-content;
      }
      p {
        width: 100%;
        line-height: 20px;
        text-align: right;
        padding: 8px 0;
      }
    }
  }
}
</style>